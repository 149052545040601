import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import realtyLiveMockup from './assets/realtylivemockup.png';
import realtyliveLogo from './assets/realty-live-logo.png';

const AppFeature = () => {
    return (
        <div className="mb-16 flex flex-col items-center gap-24 px-4 pb-0 pt-4 md:px-20 md:pb-16">
            <div className="flex flex-col gap-2 lg:gap-16">
                <div>
                    <h1 className="text-3xl font-semibold text-neutral-300 md:text-[40px] md:leading-[80px]">
                        Realty's{' '}
                        <span className="bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                            proprietary alerts engine
                        </span>{' '}
                        uses{' '}
                        <span className="bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                            AI-powered semantic analysis
                        </span>
                        , to match
                        <span className="ml-3 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                            buyer briefs
                        </span>{' '}
                        with
                        <span className="ml-3 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                            property listings
                        </span>{' '}
                        in real time.
                    </h1>
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-8 md:flex-row">
                <div className="relative w-full max-w-[600px] lg:w-[900px]">
                    <Image
                        src={realtyLiveMockup}
                        layout="responsive"
                        width={500}
                        height={500}
                        className="object-contain"
                        alt="Realty Live App Mockup"
                    />
                </div>
                <div className="flex flex-col items-center justify-center gap-6 sm:flex-row md:flex-col md:items-start">
                    <div className="flex">
                        <Image
                            src={realtyliveLogo}
                            alt="RealtyLive Logo"
                            width={150}
                            height={80}
                            className="object-contain object-left"
                        />
                    </div>
                    <div className="flex w-full flex-wrap justify-center gap-4">
                        <Link href="https://apps.apple.com/us/app/realty-live/id6736514748" passHref>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex h-16 items-center rounded-lg border border-white bg-transparent px-6 text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50"
                            >
                                <FontAwesomeIcon icon={faApple} className="mr-4" style={{ fontSize: '2rem' }} />
                                <div className="flex flex-col">
                                    <span className="whitespace-nowrap text-xs font-normal md:text-sm">
                                        Download on the
                                    </span>
                                    <span className="text-base font-semibold md:text-lg">App Store</span>
                                </div>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFeature;
