import React, { useState } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './FaQ.module.scss';

interface AccordionProps {
    title: string;
    description: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-4 w-full">
            <button
                className={clsx(
                    'flex w-full items-center justify-between gap-4 rounded-lg px-4 py-2 text-lg font-semibold text-white transition-all duration-300 hover:text-purple-500',
                    {
                        'text-purple-500': isOpen,
                    }
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                <span
                    className={clsx('text-left transition-colors duration-300', {
                        'bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent': isOpen,
                    })}
                >
                    {title}
                </span>
                <span
                    className={clsx('transform transition-transform duration-300', {
                        'rotate-45 text-purple-500': isOpen,
                    })}
                >
                    <FontAwesomeIcon icon={isOpen ? faTimes : faPlus} />
                </span>
            </button>
            <div
                className={clsx('overflow-hidden px-4 text-gray-400 transition-all duration-500', {
                    'max-h-screen opacity-100': isOpen,
                    'max-h-0 opacity-0': !isOpen,
                })}
            >
                {description}
            </div>
            <div className="mt-3 border-b border-gray-600"></div> {/* Divider */}
        </div>
    );
};

const FaQ = () => {
    const [activeTab, setActiveTab] = useState('General');

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div className="flex w-full flex-col items-center px-4 py-16 md:px-20">
            <div className="mb-20 md:mb-32">
                <h1 className=" text-3xl font-semibold text-neutral-300 md:text-[40px] md:leading-[80px]">
                    By streamlining property listings, briefs and alerts,
                    <span className=" ml-3  bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                        Realty Live
                    </span>{' '}
                    allows agents to{' '}
                    <span className="bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                        close
                    </span>{' '}
                    deals faster!
                </h1>
            </div>

            <h1 className="text-left text-3xl font-bold text-white md:text-center md:text-5xl">
                <span className="bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent">F</span>
                requently{' '}
                <span className="bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent">A</span>
                sked{' '}
                <span className="bg-gradient-to-r from-purple-500 to-purple-600 bg-clip-text text-transparent">Q</span>
                uestions
            </h1>

            <div className="relative w-full">
                <div
                    className={clsx(
                        styles.tabsContainer,
                        'mt-8 flex justify-start space-x-4 overflow-x-auto px-2 xl:justify-center',
                        { paddingBottom: '8px', paddingLeft: '16px', paddingRight: '16px' }
                    )}
                >
                    {[
                        'General',
                        'For Selling Agents',
                        'For Buying Agents',
                        'Account & Security',
                        'Techinical Support',
                    ].map((tab) => (
                        <button
                            key={tab}
                            className={clsx(
                                'whitespace-nowrap rounded-lg px-4 py-2 text-lg font-semibold text-white transition-colors duration-300',
                                {
                                    'bg-purple-600 bg-opacity-20 text-purple-600': activeTab === tab,
                                }
                            )}
                            onClick={() => handleTabClick(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
            </div>

            <div className="mt-14 w-full">
                {activeTab === 'General' && (
                    <>
                        <Accordion
                            title="What is Realty Live?"
                            description="Realty Live is a real estate platform designed to connect buying and selling agents, allowing them to list properties, match buyers, and close deals quickly and securely."
                        />
                        <Accordion
                            title="How do I download the app?"
                            description='Realty Live is available for download on the App Store and Google Play. Simply search "Realty Live" and install the app on your mobile device.'
                        />
                        <Accordion
                            title="Is Realty Live free to use?"
                            description="Realty Live offers a basic free version with the option to upgrade to premium features for enhanced tools and capabilities. Realty Live is free for selling agents - list unlimited property free!"
                        />
                    </>
                )}
                {activeTab === 'For Selling Agents' && (
                    <>
                        <Accordion
                            title="How do I list a property on RealtyLive?"
                            description="Selling agents can record property walkthroughs directly within the app, create listings, and publish them instantly to the marketplace and social media platforms."
                        />
                        <Accordion
                            title="Can I edit or update my property listings?"
                            description="Yes, you can easily update your property details, add new media, or adjust pricing anytime directly from your app."
                        />
                        <Accordion
                            title="How does Realty Live help me promote my listings?"
                            description="You can share your listings to your social media accounts directly from the app, giving your properties more exposure to potential buyers."
                        />
                    </>
                )}
                {activeTab === 'For Buying Agents' && (
                    <>
                        <Accordion
                            title="How do I set up a buyer brief?"
                            description="Buyers agents can create detailed buyer briefs by specifying property preferences such as location, price range, and features. Realty Live will alert you when a matching property is listed."
                        />
                        <Accordion
                            title="How will I be notified of matching properties?"
                            description="You will receive real-time alerts when new listings that match your buyer's preferences are added to the platform. You will receive an email and/or a push notification, depending on your preferences."
                        />
                        <Accordion
                            title="How do I make an offer on a property?"
                            description="Once you find a property that fits your client's preferences, you can submit an offer directly through the app. All negotiations can be managed securely within Realty Live."
                        />
                    </>
                )}
                {activeTab === 'Account & Security' && (
                    <>
                        <Accordion
                            title="Is my data secure on RealtyLive?"
                            description="Yes, Realty Live uses encryption and secure protocols to ensure that all your personal and financial information is protected."
                        />
                        <Accordion
                            title="Can I manage multiple clients within one account?"
                            description="Yes, buying agents can manage multiple buyer briefs, and selling agents can handle multiple property listings from a single account."
                        />
                        <Accordion
                            title="How do I reset my password?"
                            description="You can reset your password by tapping “Forgot Password” on the login screen and following the instructions sent to your email."
                        />
                    </>
                )}
                {activeTab === 'Techinical Support' && (
                    <>
                        <Accordion
                            title="What devices are compatible with RealtyLive?"
                            description="RealtyLive works on both iOS and Android devices and is optimized for smartphones and tablets."
                        />
                        <Accordion
                            title="Who do I contact for technical support?"
                            description="If you encounter any issues, you can contact our support team through the “Help” section in the app or via email at support@realty.com.au"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default FaQ;
