import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faTimes as faTimesLight, faBars as faBarsLight } from '@fortawesome/pro-light-svg-icons';
import Link from 'next/link';

import realtyLogo from '~/assets/realty-logo.png';

import { customSocials } from '~/constants/socials';

const RealtyLiveLayout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <div className="w-full max-w-screen-xl">
            <nav className="relative flex items-center justify-between bg-[#1b1b1b] px-5 py-4 shadow-md md:px-14">
                <div className="flex items-center">
                    <Link href="/" passHref>
                        <Image
                            src={realtyLogo}
                            alt="RealtyLive Logo"
                            width={168}
                            height={48}
                            className="cursor-pointer object-contain object-left"
                        />
                    </Link>
                </div>

                <div className="hidden space-x-4 md:flex">
                    <Link href="https://www.realty.com.au/agency-sign-up" passHref>
                        <a
                            className="flex h-14 w-full items-center justify-center rounded-lg border border-white bg-transparent text-base font-medium text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50 md:w-auto md:px-8"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Add your Listings
                        </a>
                    </Link>
                    <Link href="https://buyer.realty.com.au/login" passHref>
                        <a
                            className="flex h-14 w-full items-center justify-center rounded-lg bg-gradient-to-l from-purple-500 to-purple-900 text-base font-medium text-white transition-all hover:from-purple-600 hover:to-purple-800 hover:shadow-lg hover:shadow-purple-500/50 md:w-auto md:px-8"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Login
                        </a>
                    </Link>
                </div>

                <div className="md:hidden">
                    <button onClick={toggleMenu} aria-label="Toggle menu">
                        <FontAwesomeIcon
                            icon={isOpen ? faTimesLight : faBarsLight}
                            className="text-purple-500"
                            size="2x"
                        />
                    </button>
                </div>

                {isOpen && (
                    <div className="fixed inset-0 z-50 flex flex-col bg-[#1b1b1b] p-4 shadow-lg">
                        <div className="flex items-center justify-between">
                            <Link href="/" passHref>
                                <Image
                                    src={realtyLogo}
                                    alt="RealtyLive Logo"
                                    width={168}
                                    height={48}
                                    className="cursor-pointer object-contain object-left"
                                />
                            </Link>
                            <button onClick={toggleMenu} aria-label="Close menu">
                                <FontAwesomeIcon icon={faTimesLight} className="text-purple-500" size="2x" />
                            </button>
                        </div>
                        <div className="mt-16 flex flex-col gap-4">
                            <Link href="https://www.realty.com.au/agency-sign-up" passHref>
                                <a
                                    className="rounded-lg border px-5 py-3 text-base font-medium text-white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Add your Listings
                                </a>
                            </Link>
                            <Link href="https://buyer.realty.com.au/login" passHref>
                                <a
                                    className="rounded-lg bg-gradient-to-l from-purple-500 to-purple-900 px-5 py-3 text-base font-medium text-white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Login
                                </a>
                            </Link>
                        </div>
                    </div>
                )}
            </nav>

            <main className="flex justify-center">{children}</main>

            <footer className="flex w-full flex-col items-center justify-center bg-[#292929]">
                <div className="w-full">
                    <div className="flex flex-col gap-6 px-5 py-12 md:justify-between md:px-16 md:py-16 lg:flex-row">
                        <div className="relative">
                            <Link href="/" passHref>
                                <Image
                                    src={realtyLogo}
                                    width={168}
                                    height={48}
                                    alt="RealtyLive Logo"
                                    className="cursor-pointer object-contain object-left"
                                />
                            </Link>
                            <div className="mt-6 flex w-full flex-wrap gap-4">
                                <Link href="https://apps.apple.com/us/app/realty-live/id6736514748" passHref>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex h-16 items-center rounded-lg border border-white bg-transparent px-6 text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50"
                                    >
                                        <FontAwesomeIcon icon={faApple} className="mr-4" style={{ fontSize: '2rem' }} />
                                        <div className="flex flex-col">
                                            <span className="whitespace-nowrap text-xs font-normal md:text-sm">
                                                Download on the
                                            </span>
                                            <span className="text-base font-semibold md:text-lg">App Store</span>
                                        </div>
                                    </a>
                                </Link>
                            </div>
                        </div>

                        <div className="flex w-full flex-col justify-between gap-y-12 md:flex-row lg:w-2/3 xl:w-3/5">
                            <div className="w-full md:w-1/3">
                                <span className="font-semibold text-white">ON MARKET LISTINGS</span>
                                <ul className="mt-3 space-y-3 text-sm font-light text-neutral-300">
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/buy#search">For Sale</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/rent#search">For Rent</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/sold#search">Sold</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/contact-agent">Agents</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full md:w-1/4">
                                <span className="font-semibold text-white">QUICK LINKS</span>
                                <ul className="mt-3 space-y-3 text-sm font-light text-neutral-300 ">
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/privacy">Privacy Policy</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/terms-of-service">Terms of Service</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full md:w-2/5">
                                <span className="font-semibold text-white">SOCIAL</span>
                                <div className="mt-3 flex flex-wrap gap-4 text-neutral-300  ">
                                    {customSocials.map(({ link, title, icon }) => (
                                        <a
                                            className="h-6 w-6"
                                            key={link}
                                            href={link}
                                            title={title}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={icon}
                                                alt={title}
                                                className="h-full w-full object-contain invert filter"
                                            />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default RealtyLiveLayout;
