import React from 'react';

import HeroRealtyApp from './components/HeroRealtyApp';
import PartnersLogo from './components/PartnersLogo';
import AppFeature from './components/AppFeature';
import ForSellingAgent from './components/ForSellingAgent';
import ForBuyingAgent from './components/ForBuyingAgent';
import NetworkEffect from './components/NetworkEffect';
import FaQ from './components/FaQ';
import RealtyLiveLayout from '../../layouts/RealtyLiveLayout';

type Props = {
    totalAgentBriefs: number;
    totalCurrentPropertyDaily: number;
    totalAlertsCount: number;
};

const LiveStreamLandingPage = (props: Props) => {
    const { totalAgentBriefs, totalCurrentPropertyDaily, totalAlertsCount } = props || {};

    return (
        <div className="flex justify-center bg-[#1b1b1b]">
            <RealtyLiveLayout>
                <div className="flex w-full flex-col items-center">
                    <HeroRealtyApp
                        propertyTodayCount={totalCurrentPropertyDaily}
                        totalAgentBriefs={totalAgentBriefs}
                        totalAlertsCount={totalAlertsCount}
                    />
                    <PartnersLogo />
                    <NetworkEffect />
                    <AppFeature />
                    <ForSellingAgent />
                    <ForBuyingAgent />
                    <FaQ />
                </div>
            </RealtyLiveLayout>
        </div>
    );
};

export default LiveStreamLandingPage;
