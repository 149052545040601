import React, { useState, useEffect } from 'react';

import listMockup from './assets/listmockup.png';
import recordMockup from './assets/recordmockup.png';
import shareMockup from './assets/sharemockup.png';
import ShareSvg from './assets/svg/ShareSvg';
import RecordSvg from './assets/svg/RecordSvg';
import ListSvg from './assets/svg/ListSvg';

const ForSellingAgent = () => {
    const [hoveredList, setHoveredList] = useState(false);
    const [hoveredRecord, setHoveredRecord] = useState(false);
    const [hoveredShare, setHoveredShare] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="flex w-full flex-col items-center gap-12 px-4 py-8 md:px-10 lg:px-20 lg:py-16">
            <div className="flex w-full justify-center gap-2 text-center">
                <h1 className="text-4xl font-semibold text-white md:text-5xl lg:text-6xl">For Selling Agents</h1>
            </div>

            <div className="flex w-full flex-col justify-center gap-4 md:flex-row lg:gap-10">
                {/* Card for List */}
                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg sm:w-full md:w-1/3 lg:w-1/4"
                    onMouseEnter={() => setHoveredList(true)}
                    onMouseLeave={() => setHoveredList(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={listMockup} className="h-full w-full object-cover object-bottom" alt="List Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-14 w-12">
                                <ListSvg isHovered={hoveredList} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">List</h2>
                        </div>
                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            Add a pre-market or off-market listing directly to the marketplace, allowing you to get
                            offers straight away. You just need an address and a price.
                        </p>
                    </div>
                </div>

                {/* Card for Record */}
                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg sm:w-full md:w-1/3 lg:w-1/4"
                    onMouseEnter={() => setHoveredRecord(true)}
                    onMouseLeave={() => setHoveredRecord(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={recordMockup} className="h-full w-full object-cover object-top" alt="Record Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-12 w-12">
                                <RecordSvg isHovered={hoveredRecord} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">Record</h2>
                        </div>

                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            Record a property walkthrough and add it to the listing, meaning BA’s can see the property
                            before you’ve even driven away!
                        </p>
                    </div>
                </div>

                {/* Card for Share */}
                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg sm:w-full md:w-1/3 lg:w-1/4"
                    onMouseEnter={() => setHoveredShare(true)}
                    onMouseLeave={() => setHoveredShare(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={shareMockup} className="h-full w-full object-cover object-top" alt="Share Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-10 w-10">
                                <ShareSvg isHovered={hoveredShare} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">Share</h2>
                        </div>

                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            Share your walkthroughs straight to your social media accounts, with the press of a button.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForSellingAgent;
