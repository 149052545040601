import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';

import coastRealty from './assets/coastrealty.png';
import elders from './assets/elders.png';
import harcourts from './assets/harcourts.png';
import investormate from './assets/investormate.png';
import lgHooker from './assets/ljhooker.png';
import loweeCo from './assets/loweeco.png';
import mcGrath from './assets/mcgrath.png';
import pinnacleBa from './assets/pinnacleba.png';
import rayWhite from './assets/raywhite.png';
import smartInvestor from './assets/smartinvestor.png';
import theAgency from './assets/theagency.png';
import wayFinder from './assets/wayfinder.png';
import zackProperty from './assets/zackproperty.png';

import styles from './PartnersLogo.module.scss';

const imageList = [
    coastRealty,
    elders,
    harcourts,
    lgHooker,
    loweeCo,
    mcGrath,
    rayWhite,
    theAgency,
    investormate,
    pinnacleBa,
    smartInvestor,
    wayFinder,
    zackProperty,
];

const PartnersLogo = () => {
    const [imageWidth, setImageWidth] = useState(100);
    const scrollRef = useRef<HTMLDivElement>(null);
    const animationFrameIdRef = useRef<number | null>(null);

    useEffect(() => {
        const handleResize = () => {
            setImageWidth(window.innerWidth < 768 ? 100 : 180); // Set width based on screen size
        };

        handleResize(); // Set initial width
        window.addEventListener('resize', handleResize); // Add event listener for window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up event listener
        };
    }, []);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (!scrollContainer) return;

        const scrollWidth = scrollContainer.scrollWidth / 2;
        let startPosition = 0;

        const animateScroll = () => {
            startPosition += 1;
            if (startPosition >= scrollWidth) {
                startPosition = 0;
            }
            scrollContainer.scrollLeft = startPosition;
            animationFrameIdRef.current = requestAnimationFrame(animateScroll);
        };

        animateScroll();

        return () => {
            if (animationFrameIdRef.current !== null) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.container}>
            <div ref={scrollRef} className={styles.scrollContainer}>
                {imageList.concat(imageList).map((imageUrl, index) => (
                    <div key={index} className={styles.logoItem}>
                        <Image
                            layout="fixed"
                            width={imageWidth}
                            height={100}
                            className="object-contain"
                            src={imageUrl}
                            alt={`Partner Logo ${index}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnersLogo;
